/*
Following BEM naming convention:    block__element--modifier
*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/*
Following Apple dev color schemes MacOS:
https://developer.apple.com/design/human-interface-guidelines/color#System-colors
*/
:root {
  --bg-color: rgb(44, 44, 46);
  --bg-color-raw: 44, 44, 46;
  /* Default (Light) Mint */
  --primary-color: rgb(25, 118, 210);
  --primary-color-raw: 25, 118, 210;
  --secondary-color: rgb(108, 108, 108);
  --secondary-color-raw: 108, 108, 108;
  --primary-text: rgb(187, 182, 182);
  /* Default (dark) Blue */
  --link-color: rgb(10, 132, 255);
  --link-color-raw: 10, 132, 255;
  --link-adornment-color: rgba(var(--link-color), 0.4);

}

[data-theme="dark"] {
  --bg-color: rgb(44, 44, 46);
  --primary-text: rgb(187, 182, 182);
}

body > * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text);
}

a {
  color: var(--link-color);
}

/* Button styling */
.MuiButton-outlinedPrimary {
  border-width: 2px;
  /* background-color: rgba(var(--link-color-raw), 0.15) !important; */
}
/* Button styling */
.MuiButton-outlinedPrimary:hover {
  border-width: 2px;
  /* background-color: rgba(var(--link-color-raw), 0.15) !important; */
}
.MuiInputLabel-filled {
  color: rgb(var(--secondary-color));
}
.MuiFilledInput-underline::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
}

/* .MuiButton-outlinedPrimary:hover {
  background-color: rgba(var(--link-color-raw), 0.4) !important;
} */

/* MacOS style scrollbar*/
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-top: 1rem ;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(var(--secondary-color-raw), 0.5);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
}

::-webkit-scrollbar-thumb:hover {
  -webkit-border-radius: 20px;
  background: rgba(var(--secondary-color-raw), 1);
  cursor: grabbing;
}

/* Other css */
.clickable:not([disabled], [aria-disabled="true"]) {
  cursor: pointer;
}

.property-heading {
  font-size: 15px;
  font-weight: 500 bold;
  margin-bottom: 0.1rem;
}
