/* Project page */
.projects-page__layout {
  display: flex;
  justify-content: center;
  height: 100%;
}

.projects-page__content {
  display: flex;
  flex-direction: column;
	padding-inline: 2rem;
	padding-block-start: 1rem;
	padding-block-end: 3rem;
	width: 1000px;
}

/* Project page content */
.projects__header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.projects__shadow--top {
  box-shadow: 0px 10px 5px 10px var(--bg-color);
  height: 15px;
  margin-right: 15px;
  position: relative;
  z-index: 1;
}

.projects__shadow--bottom {
  box-shadow: -20px -10px 20px 15px var(--bg-color);
  height: 20px;
  margin-bottom: -20px;
  margin-right: 15px;
  position: relative;
  z-index: 1;
  max-width: inherit;
}

.projects__content {
  overflow-y: auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(310px, max-content));
	justify-content: center;
	row-gap: 2rem;
}

.projects__content-row {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* Project Card */
.project-card__base {
  display: flex;
  flex-direction: column;
  margin-inline: 1rem;
	margin-block: 2rem;
  text-transform: none;
	width: 280px;
	list-style-type: none;
}

.project-card__image-container {
  color: var(--link-color);
  width: 280px;
  height: 280px;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.3);
  background-color: var(--bg-color);
  position: relative;
}

.project-card__image-container:hover {
  border: 1px solid var(--link-color);
  z-index: 20;
}

.project-card__image-container>img {
  max-width: 280px;
  max-height: 280px;
  z-index: 10;
  padding: 1px;
}

.project-card__input-label {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  text-align: center;
  z-index: 10;
}

.project-card__delete-overlay {
  align-items: center;
  display: flex;
  height: inherit;
  justify-content: center;
  position: absolute;
  top: 0px;
  width: inherit;
}
